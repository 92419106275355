import { graphql, useStaticQuery } from 'gatsby';

const useQuery = () => {
  const query = useStaticQuery(
    graphql`
  query {
    userPicture: contentfulContentMedia(
      contentful_id: { eq: "2BpWTLgr5Gg9CtzUFvwWU9" }
    ) {
      media {
        fluid(quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    aboutUsCard: contentfulCard(
      contentful_id: { eq: "3z7ooDB00h5VhyrszwH5pW" }
    ) {
      title
      subtitle
      description {
        description
      }
      buttonText
    }
    event: allContentfulEventListings {
      edges {
        node {
          media {
            fluid(maxHeight: 800, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          id
          title
          subtitle
          description {
            description
          }
          buttonText
          eventUrl
        }
      }
    }
    socialMediaCard: contentfulCard(
      contentful_id: { eq: "2lU4tbm0id00Homx97lXEL" }
    ) {
      title
      subtitle
      description {
        description
      }
      buttonText
    }
  }
` )
  return query
}

export default useQuery;

