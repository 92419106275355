import React from 'react';
import Img from 'gatsby-image';

import Fade from 'react-reveal/Fade';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Card from '../components/card';

import styles from './about-us.module.css';

import useQuery from '../queries/about-us-query';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const AboutUs = () => {
  const { userPicture, aboutUsCard, event } = useQuery();

  return (
    <Layout>
      <SEO title="About Us" />

      <div className={styles.aboutContentContainer}>
        <Fade duration={2750}>
          <div className={styles.aboutContentItem}>
            <Img
              className={styles.aboutContentImage}
              fluid={userPicture.media.fluid}
              alt="about-us"
            />
          </div>
        </Fade>

        <div className={styles.aboutContentCard}>
          <Fade right duration={2000}>
            <Card
              className={styles.aboutCard}
              title={aboutUsCard.title}
              subtitle={aboutUsCard.subtitle}
              desc={aboutUsCard.description.description}
            />
          </Fade>
        </div>
      </div>
      <Carousel
        autoPlay
        autoPlaySpeed={3000}
        transitionDuration={500}
        responsive={responsive}
        containerClass={styles.containerClass}
      >
        {event.edges.map(edge => {
          return (
            <div className={styles.eventContentContainer} key={edge.node.id}>
              <Img
                className={styles.eventImage}
                fluid={edge.node.media.fluid}
                alt="event"
              />
              <div className={styles.eventContentCard}>
                <Fade right delay={500} duration={2000}>
                  <Card
                    className={styles.eventCard}
                    backgroundColor="white"
                    title={edge.node.title}
                    subtitle={edge.node.subtitle}
                    desc={edge.node.description.description}
                    buttonText={edge.node.buttonText}
                    eventUrl={edge.node.eventUrl}
                  />
                </Fade>
              </div>
            </div>
          );
        })}
      </Carousel>

      <div className={styles.contentContainer} />
    </Layout>
  );
};

export default AboutUs;
